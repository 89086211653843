import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../propTypes';
import Header from './Header';

const Post = ({ children, title, date, icon, tags }) => (
  <article>
    <Header title={title} date={date} icon={icon} tags={tags} />
    {children}
  </article>
);

Post.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  icon: propTypes.image,
  tags: propTypes.tags,
};

Post.defaultProps = {
  children: null,
  date: '',
  icon: null,
  tags: [],
};

export default Post;
