import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import HtmlContent from '../components/HtmlContent';
import Layout from '../components/Layout';
import Post from '../components/Post';

const PostTemplate = ({ data }) => {
  const { body, fields, frontmatter } = data.post;
  const { title, icon, date, description, tags } = frontmatter;
  const { slug } = fields;

  return (
    <Layout title={title} description={description} slug={slug}>
      <Post title={title} icon={icon} date={date} tags={tags}>
        <HtmlContent>{body}</HtmlContent>
      </Post>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default PostTemplate;

export const postTemplateQuery = graphql`
  query PostTemplate($slug: String!) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        icon {
          ...ArticleIcon
        }
        date
        description
        tags
      }
      body
    }
  }
`;
