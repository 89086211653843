import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../propTypes';
import Icon from './Icon';
import TagList from './TagList';

const formatDate = date => new Date(date).toLocaleString('default', { dateStyle: 'long' });

const Header = ({ title, icon, date, tags }) => {
  const hasHeaderDetails = tags?.length > 0 || date;

  return (
    <header className="header">
      <div className="header-title">
        {icon && <Icon className="is-hidden-mobile" icon={icon} size="lg" />}
        <h1>{title}</h1>
      </div>
      {hasHeaderDetails && (
        <div className="header-details">
          <div>
            <TagList tags={tags} />
          </div>
          <div>
            {date && (
              <time className="date" dateTime={date}>
                {formatDate(date)}
              </time>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  icon: propTypes.image,
  date: PropTypes.string,
  tags: propTypes.tags,
};

Header.defaultProps = {
  icon: null,
  date: '',
  tags: [],
};

export default Header;
