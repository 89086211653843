import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import React from 'react';

const HtmlContent = ({ children }) => <MDXRenderer>{children}</MDXRenderer>;

HtmlContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HtmlContent;
